<template>
  <transition name="slide">
    <div v-if="cookieBar" class="fixed z-50 bottom-0 right-0 left-0 bg-secondary-lightest flex justify-center">
      <div class="flex flex-col items-center w-full sm:w-3/4 lg:w-1/2 py-10">
        <header class="font-bold mb-2 text-center">
          Mijn Superkracht.nl gebruikt cookies
        </header>
        <p class="text-center">
          Deze website gebruikt cookies om te site bruikbaar te houden.<br>
          We vragen je eenmailig hiervoor toestemming te geven.<br>
          <nuxt-link to="/" class="link-secondary">Meer over ons cookiebeleid</nuxt-link>
        </p>
        <footer class="flex flex-wrap mt-4 justify-center">
          <div class="sm:pr-12 mb-6 sm:mb-0 flex flex-col sm:block">
            <toggle-option v-model="isToggleOn" on-text="Functionele cookies aan" off-text="Functionele cookies uit" class="mb-2"/>
            <toggle-option v-model="isToggleOn" on-text="Social media cookies aan" off-text="Social media cookies uit"/>
          </div>
          <div class="w-full sm:w-auto flex sm:block justify-center">
            <button type="submit" class="btn btn-secondary" @click="closeCookiebar">
              Accepteren en doorgaan
            </button>
          </div>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import ToggleOption from '../../components/forms/ToggleOption';
export default {
  name: 'CookieBar',
  components: {ToggleOption},
  data() {
    return {
      isToggleOn: true,
      cookieBar: false,
    };
  },
  methods: {
    closeCookiebar() {
      this.cookieBar = !this.cookieBar;
    },
  },
};
</script>
