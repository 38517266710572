<template>
  <div>
    <div class="bg-primary-light shadow-xl lg:shadow-none fixed z-50 left-0 right-0 top-0 lg:relative">
      <div class="container h-16 lg:h-25 flex items-center justify-between">
        <section class="flex items-center mr-2 lg:mr-12">
          <button aria-label="Open menu" class="block mr-2 h-8 lg:hidden" @click="toggleMenu">
            <img src="~/assets/img/icons/hamburger.svg" class="h-full">
          </button>
          <nuxt-link to="/">
            <img src="~/assets/img/logo/logo.svg" class="max-h-12 object-contain" alt="Mijn Superkracht.nl">
          </nuxt-link>
        </section>

        <!--// Menu desktop //-->

        <nav class="flex-grow hidden lg:block">
          <ul class="main-menu flex font-bold">
            <li v-for="(item, index) in mainMenu" :key="index">
              <div v-if="item.text.split(' ').length > 1" class="top-title cursor-pointer">
                {{ item.text.split(' ').slice(0, -1).join(' ') }}
              </div>

              <div v-if="item.children" class="cursor-pointer whitespace-no-wrap flex items-center">
                <nuxt-link :to="item.url" exact-active-class="text-secondary" class="text-base">
                  {{ item.text.split(' ').length > 1 ? item.text.split(' ').pop() : item.text | ucFirst }}
                </nuxt-link>
                <font-awesome-icon
                  class="ml-2"
                  :icon="activeChild === index ? ['fas', 'caret-up'] : ['fas', 'caret-down']"
                  @click="toggleChildren(index)"
                />
              </div>

              <template v-else>
                <nuxt-link :to="item.url" exact-active-class="text-secondary">
                  <font-awesome-icon v-if="item.text === 'Home'" :icon="['fas', 'home-lg']" class="mr-2"/>
                  <span class="text-base">
                    {{ item.text.split(' ').length > 1 ? item.text.split(' ').pop() : item.text | ucFirst }}
                  </span>
                </nuxt-link>
              </template>

              <transition v-if="item.children && activeChild === index" name="slide">
                <nav
                  class="menu-2nd-lvl bg-secondary-dark absolute left-0 right-0"
                >
                  <div class="menu-2nd-lvl--content container">
                    <ul v-for="child in item.children" :key="child.url + child.text" class="flex-1 flex-col mr-4">
                      <li v-if="child.url === '#'" class="menu-2nd-lvl--header font-bold mb-2">
                        {{ child.text }}
                      </li>
                      <li v-else class="menu-2nd-lvl--option">
                        <nuxt-link :to="child.url">
                          {{ child.text }}
                        </nuxt-link>
                      </li>
                      <template v-if="child.children">
                        <li v-for="grandChild in child.children" :key="grandChild.id" class="menu-2nd-lvl--option">
                          <nuxt-link :to="grandChild.url">
                            {{ grandChild.text }}
                          </nuxt-link>
                        </li>
                      </template>
                    </ul>
                  </div>
                </nav>
              </transition>
            </li>
          </ul>
        </nav>
        <!--// End menu desktop //-->

        <section class="flex flex-wrap justify-end items-center font-bold">
          <button class="btn btn-secondary">
            <a :href="portalUrl('/login')">Inloggen</a>
          </button>
          <a href="/registreren" class="hidden lg:block text-secondary text-sm underline lg:ml-5">
            Registreren
          </a>
        </section>
      </div>
    </div>

    <!--// Mobile menu //-->
    <transition name="slide">
      <nav v-if="menuMobile" class="mobile-menu fixed left-0 right-0 bottom-0 flex lg:hidden">
        <ul class="w-full container overflow-y-scroll pt-8 font-bold">
          <li v-for="(item, index) in mainMenu" :key="index" class="mb-6" @click="toggleChildren(index)">
            <div v-if="item.text.split(' ').length > 1" class="text-xs text-secondary-light">
              {{ item.text.split(' ').slice(0, -1).join(' ') }}
            </div>

            <div v-if="item.children" class="cursor-pointer">
              <span class="text-base">
                {{ item.text.split(' ').length > 1 ? item.text.split(' ').pop() : item.text | ucFirst }}
              </span>
              <font-awesome-icon
                :icon="activeChild === index ? ['fas', 'caret-up'] : ['fas', 'caret-down']"
                class="ml-2"
              />
            </div>

            <template v-else>
              <nuxt-link :to="item.url" exact-active-class="text-secondary" @click.native="toggleMenu">
                <font-awesome-icon v-if="item.title === 'Home'" :icon="['fas', 'home-lg']" class="mr-2"/>
                <span class="text-base">
                  {{ item.text.split(' ').length > 1 ? item.text.split(' ').pop() : item.text | ucFirst }}
                </span>
              </nuxt-link>
            </template>

            <transition v-if="item.children && activeChild === index" name="slide">
              <nav class="menu-2nd-lvl">
                <div class="flex flex-col flex-wrap my-4">
                  <ul v-for="child in item.children" :key="child.url" class="w-full">
                    <li
                      v-if="child.url === '#'"
                      class="menu-2nd-lvl--header font-bold mb-2"
                    >
                      {{ child.text }}
                    </li>
                    <li
                      v-else
                      class="menu-2nd-lvl--option"
                    >
                      <nuxt-link :to="child.url">
                        {{ child.text }}
                      </nuxt-link>
                    </li>
                    <ul>
                      <li
                        v-for="grandChild in child.children"
                        :key="grandChild.url"
                        class="menu-2nd-lvl--option"
                      >
                        <nuxt-link :to="grandChild.url">
                          {{ grandChild.text }}
                        </nuxt-link>
                      </li>
                    </ul>
                  </ul>
                </div>
              </nav>
            </transition>
          </li>
        </ul>
      </nav>
    </transition>
    <!--// End mobile menu //-->

    <div class="placeholder-bar block lg:hidden"/>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data: () => ({
    menuMobile: false,
    activeChild: null,
  }),
  computed: {
    ...mapState({
      mainMenu: state => state.mainMenu,
    }),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.menuMobile = false;
        this.activeChild = null;
      },
    },
  },
  methods: {
    toggleChildren(index) {
      this.activeChild = this.activeChild === index ? null : index;
    },
    toggleMenu() {
      this.menuMobile = !this.menuMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-menu {
  .top-title {
    @apply hidden;
  }

  li:not(.menu-2nd-lvl--option):not(.menu-2nd-lvl--header) {
    @apply mr-2 pt-2 transition duration-300 text-sm border-b-2;
    border-color: transparent;

    @screen xl {
      @apply mr-10;
    }
    &:hover:not(.menu-2nd-lvl--option):not(.menu-2nd-lvl--header) {
      @apply text-secondary border-b-2 border-secondary;
      .top-title {
        @apply h-0 w-0 block text-secondary-dark font-bold text-sm opacity-75 whitespace-no-wrap;
        transform: translateY(-15px);
      }
    }

    a {
      @apply flex items-center;
    }
  }
}

.menu-2nd-lvl {
  top: 100px;

  &--content {
    @apply w-1/2 py-8 flex justify-center items-start text-white;
  }

  &--option {
    @apply mb-2 py-1 border-2 rounded font-light border-secondary-lightest transition duration-300 ease-out;
    &:hover {
      @apply bg-secondary;
    }

    @screen lg {
      border-color: rgba(253, 253, 253, .5);
    }
    a {
      @apply flex w-full px-4;
    }
  }
}

.mobile-menu {
  z-index: 49;
  top: 64px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: theme('colors.primary.light');
}

.placeholder-bar {
  height: 64px;
}
</style>
