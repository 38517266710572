import {page as pageQuery} from '~/graphql/queries/page';
import initQuery from '~/graphql/queries/init.gql';

export const state = () => ({
  release: process.env.RELEASE,
  page: {seo: {}, template: {}},
  footerMenu: [],
  mainMenu: [],
  quickMenu: [],
  socials: [],
  contactInfo: [],
});

export const actions = {
  async nuxtServerInit({commit}) {
    const {data: {settings, menus}} = await this.app.apolloProvider.defaultClient.query({
      query: initQuery,
      variables: {
        menus: ['Hoofdmenu', 'Footermenu', 'Quickmenu'],
      },
      update: data => data,
    });

    const menuList = {};
    menus.forEach(menu => menuList[menu.name] = menu.items);

    commit('SET_SETTINGS', settings);
    commit('SET_MAIN_MENU', menuList.hoofdmenu);
    commit('SET_FOOTER_MENU', menuList.footermenu);
    commit('SET_QUICK_MENU', menuList.quickmenu);
  },
  async getPage({commit}, slug = 'home') {
    const {
      data: {page},
    } = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      fetchPolicy: 'no-cache',
      variables: {
        slug: slug === '' ? 'home' : slug,
      },
    });

    if (!page) {
      const e = new Error();
      e.statusCode = 410;
      throw e;
    }

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_FOOTER_MENU(state, payload) {
    state.footerMenu = payload;
  },
  SET_MAIN_MENU(state, payload) {
    state.mainMenu = payload;
  },
  SET_QUICK_MENU(state, payload) {
    state.quickMenu = payload;
  },
  SET_SETTINGS(state, payload) {
    const contactInfo = {};

    payload.map((setting) => {
      if (setting.group === 'Contactgegevens') {
        contactInfo[setting.key] = setting.value;
      }
    });

    state.contactInfo = contactInfo;

    state.socials = payload.filter(setting => setting.group === 'Social media');
  },
};
