import Vue from 'vue';

const Config = {
  install(Vue) {
    Vue.mixin({
      data: () => ({
        portalBaseUrl: process.env.PORTAL_URL,
      }),
      methods: {
        portalUrl(path) {
          return this.portalBaseUrl + path;
        },
      },
    });
  },
};

Vue.use(Config);
