<template>
  <div class="flex flex-col min-h-screen">
    <toolbar class="z-50"/>
    <main class="flex-grow overflow-x-hidden z-40">
      <nuxt/>
    </main>
    <app-footer class="mt-40"/>
    <!--<cookie-bar/>-->
  </div>
</template>

<script>
import Toolbar from './partials/Toolbar';
import AppFooter from './partials/Footer';
// import CookieBar from './partials/CookieBar';

export default {
  components: {Toolbar, AppFooter},
};
</script>
