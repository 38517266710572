<template>
  <div class="flex flex-col min-h-screen background">
    <toolbar class="z-50"/>
    <main class="flex-grow overflow-x-hidden z-40">
      <nuxt/>
    </main>

    <app-footer class="mt-40"/>
    <!--<cookie-bar/>-->
  </div>
</template>

<script>
import Toolbar from './partials/Toolbar';
import AppFooter from './partials/Footer';
// import CookieBar from './partials/CookieBar';

export default {
  components: {Toolbar, AppFooter},
};
</script>

<style lang="scss" scoped>
.background {
  background-image: url('~assets/img/shapes/circle-drk-red.svg'), url('~assets/img/shapes/circle-light-red.svg'), url('~assets/img/shapes/circle-drk-red-2.svg');
  background-position: 100% 15%, -10% 50%, 60% 80%;
  background-size: 300px auto, 400px auto, 300px auto;
  background-repeat: no-repeat;
}
</style>
