<template>
  <div>
    <div class="form-switch inline-block align-middle">
      <input
        id="1"
        v-model="isToggleOn"
        :value="value"
        type="checkbox"
        name="1"
        class="form-switch-checkbox"
        @change="$emit('input', $event.target.checked)"
      >
      <label class="form-switch-label" for="1"/>
    </div>
    <label class="text-sm text-secondary-dark font-bold" for="1">{{ isToggleOn ? onText : offText }}</label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    onText: {
      type: String,
      default: 'On',
    },
    offText: {
      type: String,
      default: 'Off',
    },
  },
  data() {
    return {
      isToggleOn: false,
    };
  },
  mounted() {
    this.isToggleOn = this.value;
  },
};
</script>

<style lang="scss" scoped>
.form-switch {
  @apply relative select-none w-10 mr-2 leading-normal;
}
.form-switch-checkbox {
  @apply hidden;
}
.form-switch-label {
  @apply block overflow-hidden cursor-pointer bg-primary-medium rounded-full h-5 shadow-inner;
  transition: background-color 0.2s ease-in;
}
.form-switch-label:before {
  @apply absolute block bg-primary inset-y-0 w-5 rounded-full -ml-1;

  right: 50%;
  content: "";
  transition: all 0.2s ease-in;
}
.form-switch-checkbox:checked + .form-switch-label,
.form-switch-checkbox:checked + .form-switch-label:before {
  @apply bg-secondary-dark;
}
.form-switch-checkbox:checked + .form-switch-label {
  @apply bg-secondary shadow-none;
}
.form-switch-checkbox:checked + .form-switch-label:before {
  @apply inset-y-0 right-0;
}
</style>
