<template>
  <div>
    <form method="post" class="flex flex-wrap" @submit.prevent="submit">
      <div
        v-for="field in template.fields"
        :key="field.name"
        :class="halfFields.includes(field.label) ? 'w-1/2 px-1' : 'w-full'"
      >
        <text-area
          v-if="field.type === 'textarea'"
          :id="field.name"
          v-model="form[field.name]"
          :aria-label="field.label"
          :rows="8"
        >
          {{ field.label }}
        </text-area>
        <text-field
          v-else
          :id="field.name"
          v-model="form[field.name]"
          :required="field.required"
          :type="field.type"
          :placeholder="field.label"
          :error-messages="errors[field.name]"
        >
          {{ field.label }}
        </text-field>
      </div>
      <div class="w-full text-right">
        <button
          class="btn btn-secondary"
          :disabled="loading"
          aria-label="Verstuur"
          @click="submit"
        >
          Verstuur
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import TextField from '~/components/forms/TextField';
import TextArea from '~/components/forms/TextArea';

export default {
  components: {TextArea, TextField},
  props: {
    id: {
      type: Number,
      default: 1,
    },
    html: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      template: {},
      form: {},
      loading: false,
      halfFields: ['Naam', 'Telefoonnummer', 'E-mailadres', 'Woonplaats'],
    };
  },
  async mounted() {
    const fields = await this.$axios.get('/forms/' + this.id);

    this.template = fields.data.data;

    // Set defaults
    const that = this;
    this.template.fields.forEach((field) => {
      if (field.type === 'checkbox') {
        // Default must be array to work
        that.form[field.name] = [];
      }
    });
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$axios.post('/forms/' + this.id, {...this.form});
        this.form = {};
        this.$toast.success('Uw bericht is succesvol verzonden.');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    label(field) {
      if (field.required) {
        return `${field.label}*`;
      }

      return field.label;
    },
  },
};
</script>
