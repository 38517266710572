<template>
  <div class="min-h-screen circle">
    <img src="~/assets/img/shapes/circle-light-red.svg" class="circle--left">
    <img src="~/assets/img/shapes/circle-drk-red-2.svg" class="circle--right">
    <main class="flex-grow relative">
      <nuxt/>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Login',
};
</script>

<style lang="scss" scoped>
.circle {
  &--left {
    position: absolute;
    left: 0;
    bottom: 15%;
    transform: translateX(-40%);
  }
  &--right {
    opacity: .2;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-20%);
  }
}

</style>
