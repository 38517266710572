<template>
  <div class="mb-4">
    <label class="block h5" :for="id">
      <div class="mb-1">
        <slot/>
      </div>
      <textarea :id="id" v-model="input" :rows="rows" class="p-3 resize-y w-full border-2 border-primary-medium focus:outline-none" :placeholder="placeholder"/>
    </label>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    id: {
      type: String,
      default: 'id',
    },
    placeholder: {
      type: String,
      default: 'Typ hier je bericht..',
    },
    rows: {
      type: Number,
      default: 8,
    },
  },
  data: () => ({
    input: null,
  }),
  watch: {
    input() {
      this.$emit('input', this.input);
    },
  },
};
</script>
