import {ApolloLink, split} from 'apollo-link';
import {createHttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {BatchHttpLink} from 'apollo-link-batch-http';
import {getMainDefinition} from 'apollo-utilities';

export default function ({$cookies, store, req}) {
  const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (error.message === 'validation') {
          store.dispatch('validation/setErrors', error.extensions.validation);
        }
      });
    }
  });

  const linkOptions = {
    uri: process.env.API_URL.replace('/api', '') + '/graphql',
    fetch: (uri, options) => {
      store.dispatch('validation/clearErrors');
      options.headers.authorization = $cookies.get('auth._token.local');

      if (process.server) {
        options.headers['x-forwarded-for'] = req.connection.remoteAddress || req.socket.remoteAddress;
      }

      return fetch(uri, options);
    },
  };

  const httpLink = createHttpLink(linkOptions);
  const batchLink = new BatchHttpLink(linkOptions);

  const link = split(
    // Split based on operation type
    ({query}) => {
      const {kind, operation} = getMainDefinition(query);

      return kind === 'OperationDefinition' && ['mutation', 'subscription'].includes(operation);
    },
    httpLink,
    batchLink,
  );

  return {
    defaultHttpLink: false,
    link: ApolloLink.from([
      errorLink,
      link,
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  };
}
