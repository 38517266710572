<template>
  <div class="mb-4 block">
    <label class="block h5 mb-1" :for="id">
      <slot/>
      <span v-if="required" class="text-secondary">
        *
      </span>
    </label>
    <input
      :id="id"
      ref="input"
      :value="value"
      :required="required"
      class="appearance-none border-2 border-primary-medium rounded py-2 px-3 text-primary-medium text-sm leading-tight w-full"
      :type="type"
      :placeholder="placeholder"
      :min="min"
      @input="updateInput"
    >
    <span v-if="errorMessages.length" class="text-secondary text-sm">
      {{ errorMessages[0] }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      default: 'id',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: 'placeholder',
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => ([]),
    },
    min: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    updateInput() {
      this.$emit('input', this.$refs.input.value);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ input:focus {
  outline: theme('colors.secondary.dark') auto 1px;
}
</style>
