import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faLink,
  faFistRaised,
  faBold,
  faItalic,
  faUnderline,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faHomeLg,
  faCaretDown,
  faCaretUp,
  faMapMarkerAlt,
  faSort,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faGlobe,
  faPhone,
  faList,
  faThLarge,
  faSearch,
  faShareAlt,
  faCheck,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faFacebookSquare,
  faTwitterSquare,
  faGooglePlusSquare,
  faInstagramSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  faLink,
  faHomeLg,
  faCaretDown,
  faCaretUp,
  faFistRaised,
  faMapMarkerAlt,
  faSort,
  faTimes,
  faChevronLeft,
  faFacebookSquare,
  faTwitterSquare,
  faGooglePlusSquare,
  faInstagramSquare,
  faGlobe,
  faPhone,
  faChevronRight,
  faList,
  faThLarge,
  faSearch,
  faShareAlt,
  faBold,
  faItalic,
  faUnderline,
  faCheck,
  faLinkedin,
  faInfoCircle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
