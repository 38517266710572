// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/shapes/circle-drk-red.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/shapes/circle-light-red.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/img/shapes/circle-drk-red-2.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background[data-v-b9ec5dd4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:100% 15%,-10% 50%,60% 80%;background-size:300px auto,400px auto,300px auto;background-repeat:no-repeat}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
