<template>
  <div class="bg-primary-light pt-12 pb-6">
    <div class="container flex flex-wrap items-center space-x-4">
      <section class="w-full justify-center md:justify-start md:flex-1 flex h-full items-center">
        <nuxt-link to="/">
          <img src="~/assets/img/logo/logo.svg" class="object-contain" alt="Mijn Superkracht.nl">
        </nuxt-link>
      </section>
      <section class="w-full text-center mt-6 md:mt-0 md:text-left md:flex-1 flex flex-col lg:px-6 xl:px-0">
        <div class="h3 text-secondary mb-2">Geïnteresseerd?</div>
        <p class="mb-0">
          Wil je ook gebruik maken van Mijn Superkracht?<br>
          Neem vrijblijvend contact op of registreer direct!
        </p>
      </section>
      <section class="w-full flex justify-center mt-8 md:mt-0 md:justify-start md:flex-1">
        <nuxt-link to="/contact" class="btn btn-secondary mr-4">
          Contact
        </nuxt-link>
        <nuxt-link to="/registreren" class="btn btn-secondary">
          Registreer
        </nuxt-link>
      </section>
    </div>

    <nav class="w-full flex justify-center text-center mt-10 px-12">
      <ul class="flex flex-col lg:flex-row flex-wrap justify-between space-y-4 lg:space-y-0 lg:space-x-16">
        <li v-for="item in footerMenu" :key="item.slug">
          <a v-if="item.target" :href="item.url" :target="item.target">{{ item.text }}</a>
          <nuxt-link v-else :to="item.url" class="text-secondary underline">
            {{ item.text }}
          </nuxt-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  computed: {
    ...mapState({
      footerMenu: state => state.footerMenu,
    }),
  },
};
</script>
