import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6d240ea2 = () => interopDefault(import('../pages/begeleiders/index.vue' /* webpackChunkName: "pages/begeleiders/index" */))
const _7277cf22 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _7f9ed5ee = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _26da1de6 = () => interopDefault(import('../pages/ouders/index.vue' /* webpackChunkName: "pages/ouders/index" */))
const _3e1791f6 = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _6c87acc0 = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _296841a6 = () => interopDefault(import('../pages/vragen.vue' /* webpackChunkName: "pages/vragen" */))
const _1624e020 = () => interopDefault(import('../pages/vragen/index.vue' /* webpackChunkName: "pages/vragen/index" */))
const _199222b0 = () => interopDefault(import('../pages/vragen/_slug.vue' /* webpackChunkName: "pages/vragen/_slug" */))
const _3abf3ac6 = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/index.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/index" */))
const _56abe0de = () => interopDefault(import('../pages/begeleiders/tarieven.vue' /* webpackChunkName: "pages/begeleiders/tarieven" */))
const _27af2c83 = () => interopDefault(import('../pages/begeleiders/toolbox.vue' /* webpackChunkName: "pages/begeleiders/toolbox" */))
const _1b1afd0f = () => interopDefault(import('../pages/ouders/begeleiders/index.vue' /* webpackChunkName: "pages/ouders/begeleiders/index" */))
const _28ace1fd = () => interopDefault(import('../pages/ouders/omgeving.vue' /* webpackChunkName: "pages/ouders/omgeving" */))
const _412b3ada = () => interopDefault(import('../pages/ouders/tarieven.vue' /* webpackChunkName: "pages/ouders/tarieven" */))
const _1e11813f = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/trainingen.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/trainingen" */))
const _7fcf6885 = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/webinars.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/webinars" */))
const _c4ad81d2 = () => interopDefault(import('../pages/ouders/begeleiders/zoeken.vue' /* webpackChunkName: "pages/ouders/begeleiders/zoeken" */))
const _3908997e = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/_slug.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/_slug" */))
const _19645bc7 = () => interopDefault(import('../pages/ouders/begeleiders/_slug.vue' /* webpackChunkName: "pages/ouders/begeleiders/_slug" */))
const _70c12dda = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _f5d8f1c0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _37d7bd78 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/begeleiders",
    component: _6d240ea2,
    name: "begeleiders"
  }, {
    path: "/blogs",
    component: _7277cf22,
    name: "blogs"
  }, {
    path: "/contact",
    component: _7f9ed5ee,
    name: "contact"
  }, {
    path: "/ouders",
    component: _26da1de6,
    name: "ouders"
  }, {
    path: "/over",
    component: _3e1791f6,
    name: "over"
  }, {
    path: "/registreren",
    component: _6c87acc0,
    name: "registreren"
  }, {
    path: "/vragen",
    component: _296841a6,
    children: [{
      path: "",
      component: _1624e020,
      name: "vragen"
    }, {
      path: ":slug",
      component: _199222b0,
      name: "vragen-slug"
    }]
  }, {
    path: "/begeleiders/netwerkactiviteiten",
    component: _3abf3ac6,
    name: "begeleiders-netwerkactiviteiten"
  }, {
    path: "/begeleiders/tarieven",
    component: _56abe0de,
    name: "begeleiders-tarieven"
  }, {
    path: "/begeleiders/toolbox",
    component: _27af2c83,
    name: "begeleiders-toolbox"
  }, {
    path: "/ouders/begeleiders",
    component: _1b1afd0f,
    name: "ouders-begeleiders"
  }, {
    path: "/ouders/omgeving",
    component: _28ace1fd,
    name: "ouders-omgeving"
  }, {
    path: "/ouders/tarieven",
    component: _412b3ada,
    name: "ouders-tarieven"
  }, {
    path: "/begeleiders/netwerkactiviteiten/trainingen",
    component: _1e11813f,
    name: "begeleiders-netwerkactiviteiten-trainingen"
  }, {
    path: "/begeleiders/netwerkactiviteiten/webinars",
    component: _7fcf6885,
    name: "begeleiders-netwerkactiviteiten-webinars"
  }, {
    path: "/ouders/begeleiders/zoeken",
    component: _c4ad81d2,
    name: "ouders-begeleiders-zoeken"
  }, {
    path: "/begeleiders/netwerkactiviteiten/:slug",
    component: _3908997e,
    name: "begeleiders-netwerkactiviteiten-slug"
  }, {
    path: "/ouders/begeleiders/:slug",
    component: _19645bc7,
    name: "ouders-begeleiders-slug"
  }, {
    path: "/blogs/:slug",
    component: _70c12dda,
    name: "blogs-slug"
  }, {
    path: "/",
    component: _f5d8f1c0,
    name: "index"
  }, {
    path: "/*",
    component: _37d7bd78,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
